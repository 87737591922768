-<template>
  <div>
    <b-card
      v-if="isShowRolesSetter"
      no-body
    >
      <b-row
        align-v="center"
        class="pl-2 pr-2 m-1"
      >
        <b-col
          md="4"
          xl="4"
        >
          <h4 class="mb-0 mt-xl-0">
            {{ $t("aml.info.responsibleCheck") }}
          </h4>
        </b-col>

        <b-col
          class="d-flex justify-content-xl-end align-items-center"
          md="8"
          xl="8"
        >
          <AMLAdminSelect
            :initial-admin="mainData && mainData.admin"
            @change="onAdminChange"
          />

          <b-button
            :disabled="requestInProgress"
            class="text-left text-nowrap ml-1"
            variant="light"
            @click="changeAdmin"
          >
            {{ $t("aml.info.change") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <div>
      <b-row>
        <b-col
          md="10"
          xl="5"
        >
          <h4>
            {{ $t("aml.info.info") }}
          </h4>
        </b-col>
      </b-row>

      <b-card no-body>
        <b-list-group flush>
          <b-list-group-item
            v-for="item in rows"
            :key="item.title"
          >
            <b-row>
              <b-col
                v-if="item.title"
                md="3"
                xl="3"
              >
                <b>{{ item.title }}</b>
              </b-col>

              <b-col
                :md="9"
                :xl="9"
              >
                <span v-if="item.value.text">
                  {{ item.value.text }}
                </span>

                <span v-else>
                  <b-button
                    v-if="item.value.transaction && item.value.transaction.hash"
                    variant="link"
                    class="pl-0 font-16 text-left"
                    @click="doCopy(item.value.transaction.hash)"
                  >
                    {{ item.value.transaction.hash }}
                  </b-button>

                  <span v-if="item.value.sender">
                    <b>User: </b>
                    <b-button
                      v-if="item.value.sender && item.value.sender.username"
                      variant="link"
                      class="font-16 text-left"
                      :to="`/user/${item.value.sender.id}`"
                    >
                      {{ item.value.sender.username }}
                    </b-button>
                    <br>
                    <b>TG ID: </b> {{ item.value.sender.telegramId }} <br>
                    <b>Hash: </b>
                    <b-button
                      v-if="item.value.sender && item.value.sender.address"
                      variant="link"
                      class=" font-16 text-left"
                      @click="doCopy( item.value.sender.address)"
                    >
                      {{ item.value.sender.address }}
                    </b-button>
                  </span>

                  <span v-if="item.value.receiver">
                    <b>User: </b>
                    <b-button
                      v-if="item.value.receiver && item.value.receiver.address"
                      variant="link"
                      class="font-16 text-left"
                      :to="`/user/${item.value.receiver.id}`"
                    >
                      {{ item.value.receiver.username }}
                    </b-button>
                    <br>
                    <b>TG ID: </b> {{ item.value.receiver.telegramId }} <br>
                    <b>Hash: </b>
                    <b-button
                      v-if="item.value.receiver && item.value.receiver.address"
                      variant="link"
                      class=" font-16 text-left"
                      @click="doCopy( item.value.receiver.address)"
                    >
                      {{ item.value.receiver.address }}
                    </b-button>
                    <br>
                  </span>
                </span>
              </b-col>

              <b-col
                md="1"
                xl="1"
              >
                <div class="d-flex align-items-center justify-content-end w-100" />
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="isActions || isShowActionsByPermission">
            <b-row v-if="isActions">
              <b-col
                md="3"
                xl="3"
              >
                <b>{{ $t('aml.info.status') }}</b>
              </b-col>

              <b-col
                :md="9"
                :xl="9"
              >
                <span />

                <b-badge
                  :variant="statusConfig.color"
                  class="text-white"
                >
                  {{ statusConfig.status }}
                </b-badge>

                <b-badge
                  v-if="mainData.isRefunded"
                  variant="info"
                  class="text-white"
                >
                  {{ refund }}
                </b-badge>
              </b-col>
            </b-row>

            <b-row v-else-if="isShowActionsByPermission">
              <b-button
                class="text-left ml-auto "
                type="button"
                variant="success"
                @click="onClickApprove"
              >
                {{ $t("aml.info.approve") }}
              </b-button>

              <b-button
                class="text-left ml-1"
                type="button"
                variant="danger"
                @click="onClickReject({ refund: false })"
              >
                {{ $t("aml.info.decline") }}
              </b-button>

              <b-button
                class="text-left ml-1 mr-1"
                type="button"
                variant="warning"
                @click="onClickReject({ refund: true })"
              >
                {{ $t("aml.info.declineWithRefund") }}
              </b-button>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BButton, BCard, BCol, BListGroup, BListGroupItem, BRow, VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import waitRequest from '@/mixins/waitRequest'
import { formattedDate } from '@/tools/util'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import { amlStatus, amlStatusColors } from '@/views/apps/aml/config/amlTypes'
import doCopy from '@/mixins/doCopy'
import AMLAdminSelect from '@/views/apps/aml/components/AMLAdminSelect.vue'

export default {
  name: 'AMLId',
  components: {
    AMLAdminSelect,
    ToastificationContent,
    BButton,
    BBadge,
    BCol,
    BRow,
    BCard,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [waitRequest, checkErrorsAlert, doCopy],
  data() {
    return {
      formattedDate,
      mainData: null,
      selectAdmin: {},
      adminsOptionsList: [],
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    isShowRolesSetter() {
      return this.listOwnerPermissions.includes('aml-alerts-assign')
    },
    isShowActionsByPermission() {
      return this.listOwnerPermissions.includes('aml-alerts-process')
    },
    isActions() {
      return [amlStatus.declined, amlStatus.approved, amlStatus['in progress']].includes(this.mainData?.status)
    },
    statusConfig() {
      return { color: amlStatusColors[this.mainData?.status], status: this.mainData?.status }
    },

    rows() {
      const main = `${this.mainData?.coin} / ${this.mainData?.network} = ${this.mainData?.amount}`

      return [
        { title: this.$t('aml.info.main'), value: { text: main } },
        { title: this.$t('aml.info.hash'), value: { transaction: this.mainData?.transaction } },
        { title: this.$t('aml.info.reason'), value: { text: this.mainData?.riskLevel } },
        {
          title: this.$t('aml.info.sender'),
          value: { sender: this.mainData?.sender },
        },
        {
          title: this.$t('aml.info.receiver'),
          value: { receiver: this.mainData?.receiver },
        },
      ]
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions({
      fetAMLInfo: 'aml/getAMLInfo',
      fetchAmlAlertById: 'aml/fetchAmlAlertById',
      fetchAdminUsers: 'aml/fetchAdmins',
      setAdmin: 'aml/setAdmin',
      declineAML: 'aml/setAlertDecline',
      approveAML: 'aml/setAlertAprove',
    }),

    async initData() {
      const routeId = this.$route.params.id
      await this.fetchAmlAlertById({ id: routeId }).then(response => {
        this.mainData = response.data
      }).catch(this.checkErrorsAlert)
    },

    onClickApprove() {
      if (this.requestInProgress) return

      this.waitRequest(() => this.approveAML({ id: this.mainData.id }).then(() => {
        this.$router.go(-1)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Approved',
            icon: 'UserPlusIcon',
            variant: 'success',
          },
        })
      }).catch(this.checkErrorsAlert))
    },
    onClickReject({ refund }) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.declineAML({ id: this.mainData.id, refund }).then(() => {
        this.$router.go(-1)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rejected',
            icon: 'UserPlusIcon',
            variant: 'danger',
          },
        })
      }).catch(this.checkErrorsAlert))
    },

    changeAdmin() {
      if (this.requestInProgress) return

      this.waitRequest(() => this.setAdmin({ alertId: this.mainData.id, adminId: this.selectAdmin.id }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Responsible updated',
            icon: 'UserPlusIcon',
            variant: 'info',
          },
        })
      }).catch(this.checkErrorsAlert))
    },

    onAdminChange(admin) {
      this.selectAdmin = admin ?? {}
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
